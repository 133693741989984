// const
const MAPBOX_TOKEN =
  'pk.eyJ1Ijoib3lzbWFydCIsImEiOiJja2x5bTFvbGEwNWVxMnVvOXRkbDMwMnd2In0.K5HvElyvBkI-XwF-15BCIg';
const MUNICIPALITY_CITIES_ARR = [
  '新余市',
  '长沙市',
  '南昌市',
];

// IF you outside China please make sure IS_CHINESE = false
const IS_CHINESE = true;
const USE_ANIMATION_FOR_GRID = false;
const CHINESE_INFO_MESSAGE = (yearLength, year) =>
  `我用 Garmin手表和APP 记录了自己跑步的 ${yearLength} 年，下面列表展示的是 ${year} 年的数据，同时也为激励自己跑起来！`;
const ENGLISH_INFO_MESSAGE = (yearLength, year) =>
  `Running Journey with ${yearLength} Years, the table shows year ${year} data`;

const INFO_MESSAGE = IS_CHINESE ? CHINESE_INFO_MESSAGE : ENGLISH_INFO_MESSAGE;
const FULL_MARATHON_RUN_TITLE = IS_CHINESE ? '全程马拉松' : 'Full Marathon';
const HALF_MARATHON_RUN_TITLE = IS_CHINESE ? '半程马拉松' : 'Half Marathon';
const MORNING_RUN_TITLE = IS_CHINESE ? '清晨跑步' : 'Morning Run';
const LUNCH_RUN_TITLE = IS_CHINESE ? '上午跑步' : 'Lunch Run';
const AFTERNOON_RUN_TITLE = IS_CHINESE ? '午后跑步' : 'Afternoon Run';
const EVENING_RUN_TITLE = IS_CHINESE ? '傍晚跑步' : 'Evening Run';
const NIGHT_RUN_TITLE = IS_CHINESE ? '夜晚跑步' : 'Night Run';

const RUN_TITLES = {
  FULL_MARATHON_RUN_TITLE,
  HALF_MARATHON_RUN_TITLE,
  MORNING_RUN_TITLE,
  LUNCH_RUN_TITLE,
  AFTERNOON_RUN_TITLE,
  EVENING_RUN_TITLE,
  NIGHT_RUN_TITLE,
};

export {
  MAPBOX_TOKEN,
  MUNICIPALITY_CITIES_ARR,
  IS_CHINESE,
  INFO_MESSAGE,
  RUN_TITLES,
  USE_ANIMATION_FOR_GRID,
};

export const AVATAR =
  'https://img.ouyang.wang/usr/uploads/2021/03/07/6a93b84764606446b7bbaa3ef1b5f3f9.jpeg?x-oss-process=style/img_tx'; // Temp avatar
export const NAVS = [
  { text: 'Blog', link: 'https://ouyang.wang/' },
  { text: 'About', link: 'https://ouyang.wang/about.html' },
];

const nike = 'rgb(224,237,94)';
export const MAIN_COLOR = nike;
export const PROVINCE_FILL_COLOR = '#47b8e0';
